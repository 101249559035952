// import external modules
import { combineReducers } from 'redux';
// import internal(own) modules
import { reducer as toastrReducer } from 'react-redux-toastr';
import customizer from './customizer';
import contributor from './contributor';
import company from './company';
import invoices from './invoices';

const rootReducer = combineReducers({
  toastr: toastrReducer, // <- Mounted at toastr.
  customizer,
  contributor,
  company,
  invoices,
});

export default rootReducer;
