import { ADD_CONTRIBUTOR, RESET_CONTRIBUTOR } from '../../types';
import {Contributor} from '../../../models/Contributor';

const INITIAL_STATE: Contributor = {
  key: '',
  localId: '',
  nome: '',
  sobrenome: '',
  email: '',
  telefone: '',
  perfisEmpresa: [],
  roles: '',
  empresas: [],
  servicoSelecionado: '',
  restoreId: ''
};

export default function contributor(state = INITIAL_STATE, action: any): Contributor {
  switch (action.type) {
    case ADD_CONTRIBUTOR:
      return { ...state, ...action.contributor };
    case RESET_CONTRIBUTOR:
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
}
